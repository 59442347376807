import { useState } from 'react'

import { PlusCircleIcon } from '@heroicons/react/24/outline'
import { Grid2 as Grid } from '@mui/material'
import type { FeaturesQuery } from 'types/graphql'
import { useBoolean } from 'usehooks-ts'

import type { CellFailureProps, CellSuccessProps } from '@redwoodjs/web'
import { useMutation } from '@redwoodjs/web'
import { toast } from '@redwoodjs/web/toast'

import FeatureCard from 'src/components/FeaturesCell/FeatureCard'
import Button from 'src/components/Library/Button/Button'
import { default as EmptyComponent } from 'src/components/Library/Empty/Empty'
import { default as LoadingSpinner } from 'src/components/Library/Loading'
import SimpleForm from 'src/components/Library/SimpleForm/SimpleForm'
import Modal from 'src/components/Modal/Modal'
import {
  CREATE_FEATURE_MUTATION,
  DELETE_FEATURE_MUTATION,
  UPDATE_FEATURE_MUTATION,
} from 'src/lib/queries/Feature/Feature'
import { updateMap } from 'src/Util'

export const QUERY = gql`
  query FeaturesQuery {
    features {
      id
      name
      description
      enabled
      releaseStatus
    }
  }
`

export const Loading = () => <LoadingSpinner />

export const Empty = () => {
  const [createFeature] = useMutation(CREATE_FEATURE_MUTATION, {
    refetchQueries: ['FeaturesQuery'],
    awaitRefetchQueries: true,
  })
  const [myMap, setMyMap] = useState(
    new Map([
      ['Name', ''],
      ['Description', ''],
    ]),
  )
  const open = useBoolean(false)

  return (
    <>
      <Modal
        onClose={open.setFalse}
        open={open.value}
        childClassName={'p-6'}
        title={'Add Feature'}
        footerVisible
        onConfirm={() => {
          if (myMap.get('Name') !== '' || myMap.get('Description') !== '') {
            createFeature({
              variables: {
                input: {
                  name: myMap.get('Name'),
                  description: myMap.get('Description'),
                  enabled: false,
                },
              },
            })
            open.setFalse()
          } else {
            toast.error('Please fill out all fields')
          }
        }}
      >
        <SimpleForm
          records={myMap}
          handleChange={(key, value) => updateMap(key, value, setMyMap, myMap)}
        />
      </Modal>
      <EmptyComponent title="No Features">
        <Button fullWidth={false} onClick={() => open.setTrue()}>
          Create Feature
        </Button>
      </EmptyComponent>
    </>
  )
}

export const Failure = ({ error }: CellFailureProps) => (
  <div style={{ color: 'red' }}>Error: {error?.message}</div>
)

export const Success = ({ features }: CellSuccessProps<FeaturesQuery>) => {
  const loading = useBoolean(false)
  const open = useBoolean(false)
  const [myMap, setMyMap] = useState(
    new Map([
      ['Name', ''],
      ['Description', ''],
    ]),
  )
  const [selectedFeature, setSelectedFeature] = useState(null)

  const [createFeature] = useMutation(CREATE_FEATURE_MUTATION, {
    onCompleted: () => {
      loading.setFalse()
      setSelectedFeature(null)
      toast.success('Feature Created')
    },
    onError: () => {
      loading.setFalse()
      toast.error('Error Creating Feature')
    },
    refetchQueries: ['FeaturesQuery'],
    awaitRefetchQueries: true,
  })

  const [deleteFeature] = useMutation(DELETE_FEATURE_MUTATION, {
    onCompleted: () => {
      loading.setFalse()
      toast.success('Feature Deleted')
    },
    onError: () => {
      loading.setFalse()
      toast.error('Error Deleting Feature')
    },
    refetchQueries: ['FeaturesQuery'],
    awaitRefetchQueries: true,
  })

  const [updateFeature] = useMutation(UPDATE_FEATURE_MUTATION, {
    onCompleted: () => {
      loading.setFalse()
      toast.success('Feature Updated')
    },
    onError: () => {
      loading.setFalse()
      toast.error('Error Updating Feature')
    },
    refetchQueries: ['FeaturesQuery'],
    awaitRefetchQueries: true,
  })

  const handleModalClose = () => {
    open.setFalse()
    if (loading)
      setMyMap(
        new Map([
          ['Name', ''],
          ['Description', ''],
        ]),
      )
  }

  const handleEdit = (feature) => {
    setSelectedFeature(feature)
    setMyMap(
      new Map([
        ['Name', feature.name],
        ['Description', feature.description],
      ]),
    )
    open.setTrue()
  }

  return (
    <>
      <Modal
        onClose={() => {
          handleModalClose()
        }}
        open={open.value}
        childClassName={'p-6'}
        title={selectedFeature ? 'Edit Feature' : 'Add Feature'}
        footerVisible
        onCancel={() => {
          handleModalClose()
        }}
        onConfirm={() => {
          if (selectedFeature) {
            if (myMap.get('Name') !== '' || myMap.get('Description') !== '') {
              loading.setTrue()
              updateFeature({
                variables: {
                  id: selectedFeature.id,
                  input: {
                    name: myMap.get('Name'),
                    description: myMap.get('Description'),
                    enabled: false,
                  },
                },
              })
              open.setFalse()
            } else {
              toast.error('Please fill out all fields')
            }
          } else {
            if (myMap.get('Name') !== '' || myMap.get('Description') !== '') {
              createFeature({
                variables: {
                  input: {
                    name: myMap.get('Name'),
                    description: myMap.get('Description'),
                    enabled: false,
                  },
                },
              })
              open.setFalse()
            } else {
              toast.error('Please fill out all fields')
            }
          }
        }}
      >
        {open.value && (
          <SimpleForm
            records={myMap}
            handleChange={(key, value) =>
              updateMap(key, value, setMyMap, myMap)
            }
          />
        )}
      </Modal>
      <Grid container rowSpacing={2} columnSpacing={3}>
        <Grid size={{ xs: 12 }}>
          <Button
            variant={'outlined'}
            startIcon={<PlusCircleIcon className={'h-6 w-6'} />}
            onClick={() => {
              open.setTrue()
            }}
          >
            Create Feature
          </Button>
        </Grid>
        {features.map((feature) => {
          return (
            <Grid size={{ xs: 4 }} key={feature.id}>
              <FeatureCard
                feature={feature}
                loading={loading}
                deleteFeature={deleteFeature}
                updateFeature={updateFeature}
                handleEdit={handleEdit}
                selectedFeature={selectedFeature}
                setSelectedFeature={setSelectedFeature}
              />
            </Grid>
          )
        })}
      </Grid>
    </>
  )
}
