import React from 'react'

import { Metadata } from '@redwoodjs/web'

import FeaturesCell from 'src/components/FeaturesCell'
import PageHeader from 'src/components/PageHeader/PageHeader'

const FeatureManagementPage = () => {
  return (
    <>
      <Metadata title="Feature Management" description="Feature Management" />
      <PageHeader
        title={'Feature Management'}
        parentDataTestId="feature-management-page-header"
      />
      <div className="flex flex-1 justify-center h-[calc(100vh-5rem)]">
        <div className="px-8 pt-8 overflow-y-auto w-full h-full mb-2">
          <FeaturesCell />
        </div>
      </div>
    </>
  )
}

export default FeatureManagementPage
